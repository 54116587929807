/*********************
* Fonts
*********************/

@font-face {
	font-family: "Open Sans";
	font-weight: 300;
	font-style: normal;

	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	font-style: normal;

	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Open Sans";
	font-weight: 700;
	font-style: normal;

	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font: "Open Sans", sans-serif;

/*********************
* Colors
*********************/
$primary: #29abe2;
$primary_alt: #005b97;
$secondary: #00395e;
$grey: #e6e6e6;
$text: #58585a;

/*********************
* Reusable Styles
*********************/
/*** ID ***/

#container {
	font-family: $font;

	position: relative;

	display: flex;
	flex-direction: column;

	min-height: 100vh;

	main {
		flex-grow: 1;
	}
}
/*** Classes ***/

.btn {
	padding: 15px 30px;

	transition: background .3s;
	text-decoration: none;

	color: #fff;
	border-radius: 30px;
	background: $primary;
	box-shadow: 0 4px 4px rgba(#000, .4);
	text-shadow: 0 4px 4px rgba(#000, .4);

	&:hover {
		background: $primary_alt;
	}
}

.map_pin img {
	height: 50px;
}