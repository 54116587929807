#footer {
	background: #fff;
}

#footer_inner {
	max-width: 1200px;
	margin: 0 auto;
	padding: 3em 1em;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;

	ul {
		display: flex;

		.footer_item {
			position: relative;
			z-index: 1;

			a {
				color: $text;
				text-decoration: none;
				padding: .5em 1em;
				display: block;
				position: relative;
				transition: color .3s;
			}

			@media screen and (min-width: 751px) {

				a::after {
					height: 100%;
					width: 100%;
					background: linear-gradient(to right, $primary_alt, $primary, $primary_alt);
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					opacity: 0;
					transition: opacity .3s;
					border-radius: 5px;
				}

				&:hover a {
					color: #fff;

					&::after {
						opacity: 1;
					}
				}
			}

			&+li {
				margin-left: 1em;
			}
		}
	}
}

/***>>Media Queries***/
@media screen and (max-width: 1120px) {

	#footer_inner ul .footer_item {

		a {
			padding: .5em;
		}

		&+li {
			margin-left: .5em;
		}
	}
}

@media screen and (max-width: 970px) {

	#footer_inner .logo {
		display: none;
	}
}

@media screen and (max-width: 750px) {

	#footer_inner {
		justify-content: center;
		padding: 1em;

		ul {
			display: none;
		}
	}
}