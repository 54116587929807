#home_hero {
	padding: 14em 1em;
	position: relative;
	text-align: center;

	h1 {
		background: linear-gradient(to right, $primary_alt, $primary, $primary_alt);
		padding: .5em 2em 1em;
		font-size: 35px;
		font-weight: 300;
		color: #fff;
		position: relative;
		z-index: 10;
		display: inline-block;
		text-shadow: 0px 3px 6px #000;
	}

	.caro_inner {
		/* stylelint-disable declaration-no-important */
		position: absolute !important;
		height: 100% !important;
		/* stylelint-enable declaration-no-important */
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}

	.caro_slide {
		height: 100%;
	}


	.dots_container {
		bottom: 50%;
		z-index: 11;
		left: 50%;
		right: auto;
		width: auto;
		transform: translate(-50%, 200%); // IE Compatibility
		transform: translate(-50%, calc(-50% + 4.75em));
		background: rgba(#000, .5);
		padding: .5em 7em;
		clip-path: polygon(1em 0, calc(100% - 1em) 0, 100% 50%, calc(100% - 1em) 100%, 1em 100%, 0 50%);

		&::before {
			height: 3px;
			width: 90%;
			left: 5%;
			top: calc(50% - 2px);
			content: "";
			background: #fff;
			position: absolute;
		}
	}

	.caro_dot {
		border: 3px solid #fff;
		box-shadow: none;
		border-radius: 0;
		background: #000;
		height: 16px;
		width: 16px;
		box-sizing: border-box;
		position: relative;
		transform: rotate(45deg);
		margin: 0 .5em;

		&::before {
			height: 7px;
			width: 7px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			content: "";
			background: #fff;
			opacity: 0;
			transition: opacity .3s;
		}

		&.active::before {
			opacity: 1;
		}
	}
}

#home_about {
	max-width: 1100px;
	margin: 0 auto;
	padding: 3em 1em;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

#home_about_text {
	margin-left: 2em;
	color: $text;

	p {
		line-height: 1.5;

		&+p {
			margin-top: 2em;
		}
	}
}

#home_solutions {
	background-image: url('../images/background/img_homepage_mid.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 4em 1em;
	position: relative;
	border-top: 8px solid $grey;
	border-bottom: 8px solid $grey;

	&::before {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: rgba(#000, .5);
		position: absolute;
		content: "";
	}
}

#home_solutions_inner {
	max-width: 1000px;
	margin: 0 auto;
	color: #fff;
	position: relative;

	h2 {
		font-size: 36px;
		font-weight: 300;
		padding-bottom: .75em;
		border-bottom: 2px solid #fff;
		text-align: center;
	}
}

#home_solutions_details {
	display: flex;
	margin-top: 2em;
}

.home_solution {
	flex: 1;

	&.left {
		padding-right: 2em;
	}

	&.right {
		padding-left: 2em;
	}

	h4 {
		margin-bottom: 1.5em;
	}

	ul {
		margin-bottom: 1.5em;
		line-height: 1.4;

		li::before {
			content: "- ";
		}
	}

	.btn_solution {
		display: inline-block;
		margin-top: 1em;
		padding: .75em 4em;
		color: #fff;
		background: linear-gradient(to right, $primary_alt, $primary, $primary_alt);
		text-decoration: none;
		font-size: 20px;
		font-weight: 300;

		&:hover {
			background: $primary;
		}
	}

	&+& {
		border-left: 2px solid #fff;
	}
}

#home_info_map {
	padding: 3em 1em;
	background: radial-gradient(circle at center, #f2f2f2, #fff);
}

#home_info_map_inner {
	background: #fff;
	max-width: 1024px;
	margin: 0 auto;
	box-sizing: border-box;

	h2 {
		background: linear-gradient(to right, #fff, #f2f2f2, #fff);
		text-align: center;
		font-size: 30px;
		padding: .5em 1em;
	}
}

#home_info_map_content {
	padding: 1.5em;
	display: flex;
}

#home_info {
	line-height: 1.5;
	color: $text;

	strong {
		font-weight: 700;
	}

	h3 {
		font-weight: 700;
		color: #0071bc;
	}

	p + h3 {
		margin-top: 1.5em;
	}

	a {
		color: $text;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

#home_map {
	flex: 1;
	background: #ccc;
	margin-left: 2em;
}

/***>>Media Queries***/
@media screen and (max-width: 900px) {

	#home_hero {
		padding: 5em 1em;

		h1 {
			font-size: 24px;
			padding: .5em 2em;
		}

		.dots_container {
			bottom: 1em;
			transform: translate(-50%, 0);
		}
	}

	#home_about {
		flex-direction: column-reverse;
		padding: 1em;

		img {
			max-width: 100%;
		}
	}

	#home_about_text {
		margin: 0 0 1em 0;
	}

	#home_solutions {
		padding: 1em;
	}

	#home_solutions_inner h2 {
		font-size: 24px;
	}

	#home_solutions_details {
		margin-top: 1.5em;
		flex-direction: column;
		text-align: center;
	}

	.home_solution {

		ul {
			margin-bottom: 1em;
		}

		.btn_solution {
			font-size: 18px;
			margin-top: 0;
		}

		&.left {
			padding: 0 0 2em;
		}

		&.right {
			padding: 2em 0 0;
		}

		&+.home_solution {
			border-top: 2px solid #fff;
			border-left: none;
		}
	}
}

@media screen and (max-width: 800px) {

	#home_info_map {
		padding: 0;
	}

	#home_info_map_content {
		padding: 1em;
	}
}

@media screen and (max-width: 750px) {

	#home_hero {

		h1 {
			font-size: 20px;
			padding: .5em 1em;
		}

		.dots_container {
			display: flex;
			padding: .5em 2em;
		}
	}
}

@media screen and (max-width: 700px) {

	#home_info_map_content {
		flex-direction: column;
	}

	#home_map {
		flex: initial;
		margin: 2em 0 0;
		height: 300px;
	}
}