#contact_internal {

	.bottom_heading {
		margin-bottom: 30px;
	}
}

#contact_call_msg {
	display: flex;
	justify-content: space-around;
	padding: 15px 0;
	align-items: center;
	font-size: 22px;
	color: #fff;
	font-weight: 300;
}

#contact_info_map {
	display: flex;
	padding: 2em 2em;
}

#contact_info {
	flex: 1;

	h3 {
		color: $primary;
		font-weight: 700;
		margin-bottom: 5px;
		line-height: 1.4;
	}

	p {
		line-height: 1.4;
	}

	p + h3 {
		margin-top: 25px;
	}

	strong {
		color: #58585a;
		font-weight: 700;
	}

	a {
		color: #58585a;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

#contact_map {
	background: #ccc;
	flex: 1;
	margin-left: 1em;
}

#contact_form_wrapper {
	padding: 2em;
	background: linear-gradient(to bottom, $grey, #fff);
}

#contact_form {
	display: flex;
	flex-direction: column;

	label {
		left: -9999px;
		position: fixed;
	}

	input, textarea {
		border-radius: 5px;
		padding: 8px;
		margin: 6px 5px;
		font-family: $font;
		border: 1px solid #58585a;
		font-size: 16px;

		&.error {
			border-color: #800000;
		}
	}

	.hp_wrap {
		left: -9999px;
		position: fixed;
	}

	.btn {
		font-family: $font;
		background: linear-gradient(to left, $primary_alt, $primary, $primary_alt);
		border: 0;
		cursor: pointer;
		align-self: center;
		font-weight: 700;
		padding: 15px 80px;
		font-size: 16px;
		box-shadow: none;
		text-shadow: none;
		transition: none;
		margin: 10px 0;

		&:hover {
			background: $primary;
		}
	}

	.response_message {
		text-align: center;
		margin: 1em 0;

		.error {
			color: #800000;
		}

		.success {
			color: #006e00;
		}
	}
}

@media screen and (max-width: 750px) {

	#contact_info_map {
		flex-direction: column;
	}

	#contact_map {
		flex: initial;
		height: 300px;
		margin: 3em 0 0 0;
	}
}

@media screen and (max-width: 500px) {

	#contact_info {

		a {
			font-size: 15px;
		}
	}
}