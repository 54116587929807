#skip_nav {
	position: fixed;
	left: -9999px;
	top: -9999px;
}

#header {
	border-bottom: 3px solid $grey;
	padding: 1em;
}

#header_inner {
	display: flex;
	// align-items: flex-end;
	align-items: center;
	justify-content: space-between;
	max-width: 1200px;
	margin: 0 auto;
}

#logo_container {
	position: relative;
}

#mobile_phone {
	display: block;
	position: absolute;
	top: 50%;
	left: 1em;
	transform: translateY(-50%);

	img {
		height: 40px;
		width: 40px;
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

#toggle_mobile_nav {
	display: block;
	position: absolute;
	height: 40px;
	width: 40px;
	padding: 0;
	margin: 0;
	background: $primary_alt;
	top: 50%;
	right: 1em;
	transform: translateY(-50%);
	border: none;

	&::before, &::after {
		height: 8px;
		width: 100%;
		background: #fff;
		margin: 8px 0;
		content: "";
		display: block;
		transition: transform .3s;
	}

	&[state="open"] {

		&::before {
			transform: translateY(8px) rotate(45deg);
		}

		&::after {
			transform: translateY(-8px) rotate(-45deg);
		}
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

#nav {
	display: flex;
	align-items: center;

	.nav_item {
		position: relative;

		a {
			color: $text;
			text-decoration: none;
			padding: .5em 1em;
			display: block;
			position: relative;
			transition: color .3s;
		}

		@media screen and (min-width: 751px) {

			a::after {
				height: 100%;
				width: 100%;
				background: linear-gradient(to right, $primary_alt, $primary, $primary_alt);
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				opacity: 0;
				transition: opacity .3s;
				border-radius: 5px;
			}

			&:hover a {
				color: #fff;

				&::after {
					opacity: 1;
				}
			}
		}

		&+li {
			margin-left: 1em;
		}
	}

	.phone {
		display: flex;
		flex-direction: column;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;
			text-decoration: none;
			font-size: 14px;
			font-weight: 700;

			&.second_phone {
				flex-direction: column;
				font-size: 12px;
				font-weight: normal;

				span {
					display: inline-block;
					padding: .125em;
				}
			}

			&.owner_portal {
				margin-top: 5px;
			}

			span {
				margin-left: .5em;
			}

			&:hover span {
				text-decoration: underline;
			}
		}
	}
}

/***>>Media Queries***/
@media screen and (max-width: 1200px) {

	#nav .nav_item {

		a {
			padding: .5em;
		}

		&+li {
			margin-left: .5em;
		}
	}
}

@media screen and (max-width: 1050px) {

	#header_inner {
		flex-direction: column;
		align-items: center;
	}

	#nav_container {
		margin-top: 1em;
	}
}

@media screen and (max-width: 800px) {

	#nav {
		font-size: 14px;
	}
}

@media screen and (max-width: 750px) {

	#header {
		padding: 0;
	}

	#header_inner {
		align-items: stretch;
	}

	#logo_container {
		padding: 1em;
		text-align: center;
	}

	#nav_container {
		margin: 0;
	}

	#nav {
		font-size: 16px;
		flex-direction: column;
		align-items: stretch;
		text-align: center;
		background: linear-gradient(to right, $primary_alt, $primary, $primary_alt);
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;

		&[state="open"] {
			max-height: 200px;
		}

		.nav_item {

			a {
				color: #fff;
			}

			&+li {
				margin-left: 0;
			}
		}

		.phone {
			display: none;
		}
	}
}

@media screen and (max-width: 450px) {

	#logo_container .logo {
		max-width: 60%;
	}
}