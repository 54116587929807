#internal_wrapper {
	overflow: hidden;

	background-image: url(../images/background/img_internals.jpg);
	max-width: 1920px;
	margin: 0 auto;
	background-repeat: no-repeat;
}

#internal_content_wrapper {
	max-width: 860px;
	margin: 130px auto;
}

#internal_content {

	margin: 0 10px;
	background: #fff;
	box-shadow: 0 6px 5px rgba(#000, .4);
	color: #6b6b6b;

	p {
		line-height: 1.4;
	}
}

.content {
	padding: 60px 0;
}

#top_heading {
	padding: 20px 10px 30px;

	background: linear-gradient(to right, $secondary, $primary, $secondary);

	font-size: 32px;
	font-weight: 300;

	text-align: center;

	color: #fff;
	text-shadow: 0 4px 4px rgba(#000, .4);
}


#top_heading_bg {
	position: relative;

	min-height: 50px;

	background: $secondary;
}

#header_outer_divider {
	position: relative;
	transform: translateY(-50%);

	width: 80%;
	max-width: 430px;
	height: 30px;
	margin: 0 auto;

	text-align: center;

	background: rgba(#000, .3);

	&:before, &:after {
		position: absolute;
		top: 0;

		width: 0;
		height: 0;

		content: "";

		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
	}

	&:before {
		left: -15px;
		border-right: 15px solid rgba(#000, .3);
	}

	&:after {
		right: -15px;
		border-left: 15px solid rgba(#000, .3);
	}
}

#header_line_cut_box {
	overflow: hidden;
}

#header_line_box {
	position: relative;

	display: inline-block;

	&:before, &:after {
		position: absolute;
		z-index: 1;
		top: 50%;

		width: 999px;
		height: 2px;

		content: "";
		transform: translateY(-50%);

		background: #fff;
	}

	&:before {
		right: calc(100% - 2px);
	}

	&:after {
		left: calc(100% - 2px);
	}
}

#header_inner_divider {
	position: relative;

	display: inline-flex;

	height: 30px;
	margin: 0 auto;
	padding: 0 5px;

	background: rgba(#000, .3);

	justify-content: center;

	&:before {
		position: absolute;
		top: 0;
		left: -15px;

		width: 0;
		height: 0;

		content: "";

		border-top: 15px solid transparent;
		border-right: 15px solid rgba(#000, .3);
		border-bottom: 15px solid transparent;
	}

	&:after {
		position: absolute;
		top: 0;
		right: -15px;

		width: 0;
		height: 0;

		content: "";

		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		border-left: 15px solid rgba(#000, .3);
	}

	.header_diamond_wrapper {
		position: relative;

		display: flex;

		align-items: center;

		& + .header_diamond_wrapper {
			margin: 0 0 0 18px;

			&:before {
				position: absolute;
				right: calc(100% + 1px);

				width: 16px;
				height: 2px;

				content: "";

				background: #fff;
			}
		}
	}

	.header_diamond {
		display: flex;

		width: 15px;
		height: 15px;

		transform: rotate(45deg);

		border: 2px solid #fff;
		background: transparent;

		align-self: center;
	}
}

#tab_title_container {
	display: flex;

	width: 100%;

	justify-content: center;
	background: $secondary;

	position: relative;

	&:after {

		position: absolute;
		bottom: 0;
		left: 0;
		height: 6px;
		width: 100%;
		background: linear-gradient(transparent, #000);
		opacity: .5;
		pointer-events: none;
		content: "";
	}

	.tab_title {
		font-family: $font;
		font-size: 18px;

		position: relative;

		width: 360px;
		padding: 20px 40px;
		margin: 0 12px;
		cursor: pointer;
		outline: none;
		color: #fff;
		border: none;
		background: $primary;
		text-shadow: 0 4px 4px rgba(#000, .4);
		transition: background .3s;

		&:hover {
			background: $primary_alt;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: 50%;

			width: 0;
			height: 0;

			content: "";
			transform: translateX(-50%);

			border-bottom: 2px solid $primary;
		}

		&.active {
			color: $primary_alt;
			background: #fff;
			box-shadow: none;
			z-index: 1;

			&:after {
				right: 0;

				width: 70%;
			}
		}
	}
}

.tab_content {
	display: none;

	&.active {
		display: block;
	}
}

#multi_family, #furnished_rentals {
	position: relative;
}

#multi_family {

	p {

		&:before {
			position: absolute;
			top: 8px;
			left: 90px;

			width: 15px;
			height: 15px;

			content: "";
			transform: rotate(45deg);

			background: $primary_alt;
		}

		span {
			font-size: 20px;
			line-height: 1.4;

			vertical-align: baseline;

			color: $primary;
		}
	}
}

#furnished_rentals {

	h2 {
		font-size: 24px;

		margin: 40px 0 20px;
		padding: 0 120px;

		color: $secondary;
	}

	h3 {
		font-size: 20px;

		margin: 40px 0 20px;
		padding: 0 120px;

		color: $primary;
	}

	li {
		margin: 0 0 20px 40px;
	}
}

#available_properties {
	margin: 0 auto;
	padding: 20px 0 60px;

	text-align: center;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 80px;
		background: $grey;
		bottom: 0;
		left: 0;
	}

	a {
		position: relative;

		display: inline-block;

		text-align: center;
		z-index: 1;
	}
}

#about_us_internal {

	.about_header {
		display: flex;
		align-items: flex-start;
		color: #000;
		max-width: calc(100% - 180px);
		margin: 0 auto 20px;
		padding: 30px 0;
	}

	.about_bio {
		width: calc(100% - 260px);
		margin: 0 auto;
		padding: 40px;

		background: #e6e6e6;

		p {
			margin: 0 0 20px;
		}
	}

	.details {
		padding: 0 0 0 40px;
		font-size: 15px;

		h3 {
			font-weight: 700;
		}

		div + p {
			margin: 30px 0 0;
		}

		&.alt {
			padding: 0 20px 0 0;
		}
	}
}

#management_services_internal {

	.bottom_heading {
		height: auto;
	}

	p {
		line-height: 1.4;

		position: relative;

		padding: 0 120px 20px;
	}

	li {
		position: relative;
		margin: 0 0 20px 160px;

		&:before {
			position: absolute;
			top: 4px;
			left: -30px;

			width: 15px;
			height: 15px;

			content: "";
			transform: rotate(45deg);

			background: $primary_alt;
		}

		& + li {
			margin: 20px 0 20px 160px;
		}
	}

	.btn {
		padding: 15px 50px;
	}
}

#residential_sales, #commercial_sales {
	padding: 0 100px 60px;

	background: linear-gradient(#fff, #e6e6e6);

	h3 {
		font-size: 20px;
		font-weight: 700;
		padding: 40px 0 10px;

		color: $primary;
		border-bottom: 1px solid #58585a;
	}

	.sales_contact_section {
		margin: 20px 0 0;
		display: flex;
		flex-direction: column;
		line-height: 1.4;
	}

	p + p {
		padding: 20px 0 0;
	}

	h4, strong {
		font-weight: 700;
		color: #58585a;
	}

	h4 {
		font-size: 20px;
	}

	ul {
		margin: 20px 40px;

		list-style: disc;

		li {
			margin: 0 0 20px;
		}
	}

	a {
		color: #6b6b6b;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.btn {
		display: inline-block;
		color: #fff;
		margin: 20px 0 40px;
		padding: 15px 60px;
		transition: background .3s;

		&:hover {
			color: #fff;
		}
	}
}

#commercial_sales {

	h3 {
		padding: 50px 0 10px;
	}

	ul {
		font-weight: 700;

		li {
			margin: 5px 0;
		}
	}
}

#error_internal {

	.content {
		text-align: center;
		padding: 30px 20px 0;
	}
	/* stylelint-disable at-rule-no-unknown */

	.btn {
		display: inline-block;

		margin: 40px 0 0;

		@extend .btn;
	}
	/* stylelint-enable at-rule-no-unknown */
}

@media screen and (max-width: 750px) {

	#internal_content_wrapper {
		margin: 40px auto;
	}

	.content {
		padding: 1em 0;
	}

	#top_heading {
		font-size: 28px;
	}

	#residential_sales, #commercial_sales {
		padding: 0 50px 60px;
	}

	#furnished_rentals h2 {
		padding: 0 16px;
	}

	#management_services_internal {

		h3 {
			margin: 0 0 20px;
			padding: 0 1em;
		}

		p {
			padding: 0 1em 20px;
		}

		ul {
			margin: 0 0 40px;
			padding: 0 1em;

			li {
				margin: 0 0 20px 35px;

				&:before {
					width: 12px;
					height: 12px;
				}
			}
		}
	}

	#multi_family {

		p {

			&:before {
				left: 30px;

				width: 12px;
				height: 12px;
			}

			span {
				font-size: 18px;
			}
		}
	}

	#tab_title_container {

		.tab_title {
			font-size: 15px;

			padding: 20px 0;
		}
	}

	#about_us_internal {

		.content {
			padding: 0;
		}

		.about_header {
			max-width: 100%;
			padding: 60px 40px 10px;
		}

		.about_bio {
			box-sizing: border-box;
			width: 100%;
		}
	}
}

@media screen and (max-width: 600px) {

	#tab_title_container {

		.tab_title {
			font-size: 14px;
		}
	}
}

@media screen and (max-width: 500px) {

	#top_heading {

		font-size: 20px;
	}

	#residential_sales, #commercial_sales {
		padding: 0 20px 30px;

		strong, a {
			font-size: 15px;
		}

		.btn {
			padding: 15px 30px;
		}
	}

	#tab_title_container {

		.tab_title {
			margin: 0;
		}
	}

	#about_us_internal {

		.about_header {
			flex-direction: column;

			align-items: center;
		}

		img {
			margin: 0 0 20px;
		}

		.details {
			padding: 0;

			&.alt {
				order: 2;
			}
		}
	}

	#multi_family {

		p {
			padding: 0 30px 20px;

			&:before {
				left: 12px;
				width: 10px;
				height: 10px;
			}
		}
	}
}
